<template>
  <Panel>
    <template #title><span>Preference Set</span></template>
    <template #content>
      <el-form ref="form" label-width="80px">
        <el-form-item label="Timezone">
          <el-select v-model="value" placeholder="Please select">
            <el-option
              v-for="item in options"
              :key="item.id"
              :label="item.text"
              :value="item.utc"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="onSubmit">Submit</el-button>
        </el-form-item>
      </el-form>
    </template>
  </Panel>
</template>

<script>
import { gettimezone, saveimezone } from '@/api/user.js'
export default {
  data() {
    return {
      value: '',
      options: []
    }
  },
  created() {
    this.timezone()
  },
  methods: {
    async onSubmit() {
      const data = await saveimezone({
        timezone: this.value
      })
      if (data.status === 200) {
        this.$message({
          message: 'Submit successful',
          type: 'success'
        })
      } else {
        this.$message({
          message: data.data.toperr,
          type: 'error'
        })
      }
    },
    async timezone() {
      const data = await gettimezone()
      // console.log(data)
      this.options = data.allTimezones
      this.value = data.preference.timezone
    }
  }
}
</script>

<style scoped>
.el-input__inner,
.el-select {
  width: 100%;
}
</style>
